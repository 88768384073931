const getDate = (date) => {
  if (!date) return '';
  const getTwoDigits = (value) => (value < 10 ? `0${value}` : value);
  const formatDate = (date) => {
    const day = getTwoDigits(date.getDate());
    const month = getTwoDigits(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return formatDate(new Date(date));
};

const showDate = (n) => {
  const currentDate = new Date();
  const dateOffset = 24 * 60 * 60 * 1000 * n;
  const time = getDate(new Date(currentDate - -dateOffset));
  return time;
};

const elements = document.querySelectorAll('.js__showdate');

elements.forEach((item) => {
  const shift = Number(item.textContent);
  const date = showDate(shift);
  item.innerHTML = date;
});

$('.btnlink').click(function () {
  $('html, body').animate(
    {
      scrollTop: $('.anchor').offset().top - 300,
    },
    500
  );
  return false;
});
